<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    this.$router.push('/home');
    console.log(process.env.VUE_APP_PROXY_TARGET);
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 10rem;
  margin: 0 auto;
  overscroll-behavior-x: none;
}
</style>
