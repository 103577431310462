import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/uploadImg',
    name: 'uploadImg',
    component: () => import('../views/uploadImg.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../views/report.vue')
  },
  {
    path: '/getReport',
    name: 'getReport',
    component: () => import('../views/getReport.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/paySuccess.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: () => import('../views/payResult.vue')
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
