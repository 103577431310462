import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/public.scss';
import 'animate.css';
import Vant from 'vant';
import { Toast } from 'vant';
import 'vant/lib/index.css';
import moment from 'moment';
import axios from 'axios';
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$toast = Toast;
Vue.use(moment); // 处理时间的过滤器

moment.locale('zh-cn');
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(axios);
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
